export const themeColors = {
  rainbowgram: {
    names: '938fb5',
    headline: 'f390b5',
    body: '1a1919'
  },

  christmasgram: {
    names: 'f7f2ea',
    headline: '262635',
    body: '262635'
  },

  replygram: {
    names: '0a1951',
    headline: '0a1951',
    body: '262635'
  },

  eastergram: {
    names: 'f7f2ea',
    headline: '262635',
    body: '262635'
  },

  weddinggram: {
    names: 'f7f2ea',
    headline: '262635',
    body: '262635'
  },

  hallowgram: {
    names: 'f7f2ea',
    headline: '262635',
    body: '262635'
  }
}
