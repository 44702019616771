import 'whatwg-fetch'
import 'promise-polyfill/src/polyfill'

import 'core-js/features/object/assign'
import 'core-js/features/set'
import 'core-js/features/map'
import 'core-js/features/promise'
import 'core-js/features/array/from'
import 'core-js/features/string/includes'
import 'element-closest'
import 'intersection-observer'

if (!Element.prototype.matches) {
  Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s)

        var i = matches.length
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1
      }
}
