import Controller from 'core/controller'
import { el } from 'core/dom'
import imagesLoaded from 'imagesloaded'
import delay from 'core/delay'
import { each } from 'lodash'

export default class extends Controller {
  static targets = ['loading', 'form', 'image', 'text', 'action']

  connect () {
    imagesLoaded(this.element, instance => this.finishedLoading())
  }

  finishedLoading () {
    const loadingTarget = el(this.loadingTarget).addClass('is-stopped')
    el(this.textTarget).text('Transmitting...')

    delay(2100)
      .then(() => {
        loadingTarget.addClass('is-fading')
      })
      .then(() => delay(400))
      .then(() => {
        loadingTarget.hide()

        if (this.hasFormTarget) {
          el(this.formTarget).show()
        }

        el(this.imageTarget).show()

        each(this.actionTargets, target => el(target).show())
      })
  }
}
