import Controller from 'core/controller'
import { el } from 'core/dom'
import { isDesktop, isMobile } from 'core/responsive'
import { trigger } from 'core/events'

export default class extends Controller {
  static targets = [
    'preview',
    'formats',
    'field',
    'dropzone',
    'dropzoneLabel'
  ]

  connect () {
    if (this.hasDragDrop && isDesktop) {
      el(this.dropzoneLabelTarget).text('Drag a photo here or browse to upload')
    }

    if (isMobile) {
      el(this.dropzoneLabelTarget).text('Browse for a Photo or Take a Picture with Your Camera')
    }
  }

  get hasDragDrop () {
    if ('FormData' in window) {
      if ('FileReader' in window) {
        return true
      }
    }

    return false
  }

  onChange (event) {
    this._togglePhotoFormats()
    this.onDragOut(event)

    const file = event.target.files[0]

    if (!file) {
      return
    }

    if (!file.type.match(/^image\//)) {
      return
    }

    const img = document.createElement('img')
    const reader = new window.FileReader()
    reader.onload = event => el(img).attr('src', event.target.result)
    reader.readAsDataURL(file)

    const existingImage = this.previewTarget.querySelector('img')

    if (existingImage) {
      existingImage.remove()
    }

    this.previewTarget.appendChild(img)

    this.el.addClass('with-photo')

    trigger('reset:change-photo')
  }

  remove (event) {
    event.preventDefault()

    this.fieldTarget.value = null

    if (this.hasPreviewTarget) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = '_deleted_photo'
      input.value = '1'

      this.element.appendChild(input)

      const existingImage = this.previewTarget.querySelector('img')

      if (existingImage) {
        existingImage.remove()
      }

      this.el.removeClass('with-photo')

      this._togglePhotoFormats()
    }

    trigger('reset:change-photo')
  }

  onDragOver (event) {
    el(this.dropzoneTarget).addClass('is-over')
  }

  onDragOut (event) {
    el(this.dropzoneTarget).removeClass('is-over')
  }

  _togglePhotoFormats () {
    if (!this.hasFieldTarget) {
      return
    }

    if (this.fieldTarget.files.length > 0 || this.data.has('hasPhoto')) {
      el(this.formatsTarget).show()
    } else {
      el(this.formatsTarget).hide()
    }
  }
}
