import Controller from 'core/controller'
import { csrfParams } from 'core/util/params'
import { el } from 'core/dom'
import { fetchScript } from 'core/fetch'

export default class extends Controller {
  confirmAndDelete (e) {
    e.preventDefault()

    if (!window.confirm('Are you sure?')) {
      return
    }

    const link = el(e.target).closest('a')
    fetchScript(link.attr('href'), 'delete', csrfParams())
    this.el.fadeAndRemove()
  }
}
