import Controller from 'core/controller'
import { el } from 'core/dom'

export default class extends Controller {
  static targets = ['nav']

  connect () {
    document.querySelector('html').className = this.data.get('htmlClass')
  }

  toggleNav (event) {
    event.preventDefault()

    if (this.hasActiveNav) {
      this._deactivateNav()
    } else {
      this._activateNav()
    }
  }

  get hasActiveNav () {
    return this.data.has('active-nav')
  }

  _activateNav () {
    const nav = el(this.navTarget)

    this.el.addClass('with-mobile-nav')

    setTimeout(() => {
      nav.addClass('in')
      this.el.addClass('in')
      this.data.set('active-nav', true)
    }, 100)
  }

  _deactivateNav () {
    const nav = el(this.navTarget)

    nav.removeClass('in')
    this.el.removeClass('in')

    setTimeout(() => {
      this.el.removeClass('with-mobile-nav')
      this.data.delete('active-nav')
    }, 300)
  }
}
