import { isString, isNil } from 'lodash'

export function truncate (str, max = 100) {
  if (str.length > max) {
    return `${str.slice(0, max)}...`
  } else {
    return str
  }
}

export function isPresent (str) {
  if (isNil(str)) {
    return false
  }

  if (!isString(str)) {
    return false
  }

  return str.replace(/\s/g, '').length > 0
}

// https://github.com/jquery/jquery/blob/e743cbd28553267f955f71ea7248377915613fd9/src/core.js#L42
const rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g

export function strip (str) {
  if (str === null) {
    return ''
  }

  if (!isString(str)) {
    return ''
  }

  return (str + '').replace(rtrim, '')
}
