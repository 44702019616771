import Controller from 'core/controller'

export default class extends Controller {
  static targets = ['menu']

  connect () {
    this.el.attr('aria-expanded', false)
  }

  toggle (event) {
    event.preventDefault()

    if (this.isOpen) {
      this.el.attr('aria-expanded', false)
      this.el.removeClass('is-open')
    } else {
      this.el.attr('aria-expanded', true)
      this.el.addClass('is-open')
    }

    event._handledDropdown = this
  }

  // called when the window is clicked anywhere to hide the dropdown
  hide (event) {
    if (!this.isOpen) {
      return
    }

    // don't run hide right after we already acted on this same dropdown
    if (event._handledDropdown === this) {
      return
    }

    this.el.removeClass('is-open')
    this.el.attr('aria-expanded', false)
  }

  get isOpen () {
    return this.el.hasClass('is-open')
  }
}
