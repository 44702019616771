import { each, map } from 'lodash'
import { serialize } from 'dom-form-serializer'

export function serializeForm (element) {
  const typesToInclude = ['radio', 'checkbox', 'submit']

  const children = element.elements
  const params = []

  each(children, input => {
    if (input.type === 'select') {
      each(input.options, option => {
        if (option.selected) {
          params.push({
            name: input.name,
            value: option.value
          })
        }
      })
    } else if (input.checked || !typesToInclude.includes(input.type)) {
      params.push({
        name: input.name,
        value: input.value
      })
    }
  })

  return map(params, param => `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`).join('&')
}

export function serializeFormToJson (element) {
  return serialize(element, {
    ignoredTypes: ['input[type=file]']
  })
}
