import { includes, isEqual, isFunction } from 'lodash'

let eventNames = []
let registeredEventStreams = {}

export function on (eventName, stream) {
  if (!includes(eventNames, eventName)) {
    eventNames.push(eventName)
    registeredEventStreams[eventName] = []
  }

  if (isFunction) {
    registeredEventStreams[eventName].push(stream)
  }
}

export function off (eventName, stream) {
  if (includes(eventNames, eventName)) {
    var updatedStreams = []

    registeredEventStreams[eventName].forEach((existingStream, index) => {
      if (!isEqual(stream, existingStream)) {
        updatedStreams.push(existingStream)
      }
    })

    registeredEventStreams[eventName] = updatedStreams
  }
}

export function trigger (eventName, data = {}) {
  if (includes(eventNames, eventName)) {
    registeredEventStreams[eventName].forEach(stream => stream(data))
  }
}

export function clearEvents () {
  eventNames = []
  registeredEventStreams = {}
}
