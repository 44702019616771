// Utility wrapper for local storage
import _ from 'lodash'

const enabled = !_.isUndefined(window) && _.isObject(window.localStorage)

function storeKey (key) {
  return `zig:${key}`
}

function storeObject (store) {
  return {
    clearAll () {
      if (enabled) {
        store.clear()
        return true
      }
    },

    del (key) {
      if (enabled) {
        store.removeItem(storeKey(key))
        return true
      }
    },

    get (key, defaultVal = null) {
      if (enabled) {
        return store.getItem(storeKey(key)) || defaultVal
      } else {
        return defaultVal
      }
    },

    getObject (key, defaultVal = null) {
      const value = this.get(key)

      if (_.isString(value)) {
        try {
          return JSON.parse(value)
        } catch (e) {}
      }

      return defaultVal
    },

    set (key, value) {
      if (enabled) {
        store.setItem(storeKey(key), value)
        return value
      }
    },

    setObject (key, value) {
      if (enabled) {
        const obj = JSON.stringify(value)
        this.set(key, obj)
        return value
      }
    }

  }
}

export const localStore = storeObject(enabled ? window.localStorage : null)
export const sessionStore = storeObject(enabled ? window.sessionStorage : null)

export default {
  store: localStore,
  session: sessionStore
}
