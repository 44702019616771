import { isUndefined, isString, map, each } from 'lodash'
import delay from 'core/delay'
import DataMap from 'core/dom/data-map'

// simplified dom element wrapper for common utility methods
class DomElement {
  constructor (element) {
    this.el = element
  }

  addClass (className) {
    this.el.classList.add(className)
    return this
  }

  append (tagNameOrElement) {
    let newElement

    if (typeof tagNameOrElement === 'string') {
      newElement = document.createElement('div')
      newElement = this.el.appendChild(newElement)
      newElement.outerHTML = tagNameOrElement
    } else {
      newElement = this.el.appendChild(tagNameOrElement)
    }

    return new DomElement(newElement)
  }

  // get or set an attribute
  attr (key, newValue) {
    if (isUndefined(newValue)) {
      return this.el.getAttribute(key)
    } else {
      this.el.setAttribute(key, newValue)
      return this
    }
  }

  closest (query) {
    const result = this.el.closest(query)

    if (result) {
      return new DomElement(result)
    } else {
      return null
    }
  }

  get data () {
    if (!this._dataMap) {
      this._dataMap = new DataMap(this)
    }

    return this._dataMap
  }

  fadeAndRemove () {
    this.addClass('is-fading')

    return delay(0)
      .then(() => this.addClass('out'))
      .then(() => delay(400))
      .then(() => this.remove())
  }

  first (selector) {
    const firstElement = this.el.querySelector(selector)

    if (!firstElement) {
      return null
    }

    return new DomElement(firstElement)
  }

  find (selector) {
    return map(this.el.querySelectorAll(selector), element => new DomElement(element))
  }

  focus () {
    this.el.focus()
    return this
  }

  // returns the original dom element
  get element () {
    return this.el
  }

  get height () {
    return this.el.offsetHeight
  }

  hasAttribute (attr) {
    return this.el.hasAttribute(attr)
  }

  // returns true if the element has the given class
  hasClass (className) {
    return this.el.classList.contains(className)
  }

  hide () {
    return this.addClass('is-hidden').attr('aria-hidden', true)
  }

  html (newHtml) {
    this.el.innerHTML = newHtml
    return this
  }

  // el.insertAfter('div') => new DomElement
  // el.insertAfter(node) => new DomElement
  insertAfter (tagNameOrElement) {
    if (typeof tagNameOrElement === 'string') {
      tagNameOrElement = document.createElement(tagNameOrElement)
    }

    const newElement = this.el.parentNode.insertBefore(tagNameOrElement, this.el.nextSibling)

    return new DomElement(newElement)
  }

  get isVisible () {
    return !this.hasClass('is-hidden')
  }

  off (type, listener) {
    return this.el.removeEventListener(type, listener)
  }

  on (type, listener) {
    return this.el.addEventListener(type, listener)
  }

  remove () {
    this.el.remove()
  }

  removeAttribute (attr) {
    return this.el.removeAttribute(attr)
  }

  removeClass (className) {
    this.el.classList.remove(className)
    return this
  }

  replaceWith (newHtml) {
    this.el.outerHTML = newHtml
    return this
  }

  select () {
    this.focus()
    this.el.select()
    return this
  }

  show () {
    return this.removeClass('is-hidden').attr('aria-hidden', false)
  }

  text (newText) {
    if (isUndefined(newText)) {
      return this.el.innerText
    } else {
      this.el.innerText = newText
      return this
    }
  }

  trigger (type, data = {}) {
    const event = new window.CustomEvent(type, {
      detail: data
    })

    this.el.dispatchEvent(event)

    return event
  }

  toggle () {
    if (this.isVisible) {
      this.hide()
    } else {
      this.show()
    }
  }

  toggleClass (className) {
    this.el.classList.toggle(className)
    return this
  }

  get width () {
    return this.el.offsetWidth
  }

  value (newValue) {
    if (isUndefined(newValue)) {
      return this.el.value
    } else {
      this.el.value = newValue
      return this
    }
  }
}

export function el (element) {
  if (isString(element)) {
    element = document.getElementById(element)
  }

  return new DomElement(element)
}

export function eachEl (elements, iterator) {
  each(elements, element => {
    iterator(new DomElement(element))
  })
}
