import { isString, assign, isObject } from 'lodash'
import { toParam } from 'core/util/params'
import { cspNonce } from 'core/util/csp'

/*
  Simple wrapper for JSON-style fetching with native `fetch`

  Returns a promise to use the response.

  Usage:

    // basic get
    fetchJson('/api/dealerships').then(function(json) {
      console.log('json', json);
    }).catch(function (ex) {
      console.log('error', ex.message);
    });

    // with params
    fetchJson('/api/dealerships/1', 'post', {
      name: 'New dealership name'
    }).then(function(json) {
      console.log('json', json);
    }).catch(function (ex) {
      console.log('error', ex.message);
    });
*/
export function fetchJson (url, method = 'get', params, headers = {}) {
  let httpMethod = method
  let options = {}

  if (httpMethod !== 'get' && httpMethod !== 'post') {
    const realMethod = method.toString().toUpperCase()

    httpMethod = 'post'
    params = params || {}
    params._method = realMethod
    headers.X_HTTP_METHOD_OVERRIDE = realMethod
  }

  options = {
    method: httpMethod,
    headers: assign({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }, headers),
    credentials: 'same-origin'
  }

  if (httpMethod === 'get' && isObject(params)) {
    url = `${url}?${toParam(params)}`
  } else if (httpMethod !== 'get' && isObject(params)) {
    options.body = JSON.stringify(params)
  }

  return fetch(url, options).then(toJson)
}

export function toJson (response) {
  const json = response.json()

  if (response.status >= 200 && response.status < 300) {
    return json
  } else {
    const error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

export function fetchHtml (url, method = 'get', params, headers = {}) {
  const httpMethod = method
  let options = {}

  if (httpMethod !== 'get' && httpMethod !== 'post') {
    const realMethod = method.toString().toUpperCase()

    params = params || {}
    headers['X-HTTP-Method-Override'] = realMethod
  }

  options = {
    method: httpMethod.toUpperCase(),
    headers: assign({
      Accept: 'text/html',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      'X-Requested-With': 'XMLHttpRequest'
    }, headers),
    credentials: 'same-origin'
  }

  if (httpMethod === 'get') {
    if (isObject(params)) {
      url = `${url}?${toParam(params)}`
    } else if (isString(params)) {
      url = `${url}?${params}`
    }
  } else if (httpMethod !== 'get') {
    if (isObject(params)) {
      options.body = toParam(params)
    } else if (isString(params)) {
      options.body = params
    }
  }

  return fetch(url, options).then(toHtml)
}

export function toHtml (response) {
  const html = response.text()

  if (response.status >= 200 && response.status < 300) {
    return html
  } else {
    const error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

export function fetchScript (url, method = 'get', params, headers = {}) {
  headers.Accept = 'text/javascript'

  return fetchHtml(url, method, params, headers).then(text => {
    const script = document.createElement('script')
    script.text = text
    script.nonce = cspNonce()
    document.head.appendChild(script).parentNode.removeChild(script)
  })
}
