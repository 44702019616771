import { strip } from 'core/util/string'

const emailRegex = /\b[A-Z0-9._%a-z-]+@(?:[A-Z0-9a-z-]+\.)+[A-Za-z]{2,}/

export function isValidEmail (str) {
  str = strip(str + '')

  // a@t.co
  if (str.length < 6) {
    return false
  }

  if (str.match(emailRegex) !== null) {
    return true
  }

  return false
}

export function isValidName (str) {
  if (typeof str === 'undefined') {
    return false
  }

  if (str === null) {
    return false
  }

  str = strip(str + '')

  return str.length >= 1
}
