import Controller from 'core/controller'
import { serializeForm } from 'core/util/form'
import { fetchScript } from 'core/fetch'

export default class extends Controller {
  static targets = [ 'primaryField' ]                                           // eslint-disable-line

  connect () {
    if (this.hasPrimaryFieldTarget) {
      this.primaryFieldTarget.focus()
    }
  }

  submitRemote (event) {
    event.preventDefault()

    const method = this.el.attr('method') || 'get'
    const url = this.el.attr('action') || window.location.path
    const params = serializeForm(this.element)

    fetchScript(url, method, params)
  }
}
