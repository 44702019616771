// loosely based on https://github.com/stimulusjs/stimulus/blob/master/packages/%40stimulus/core/src/data_map.ts
export default class DataMap {
  constructor (el) {
    this.el = el
  }

  get (key) {
    key = formatKey(key)
    const value = this.el.attr(key)

    return coerceDataValue(value)
  }

  set (key, value) {
    key = formatKey(key)
    this.el.attr(key, value)
    return this.get(key)
  }

  has (key) {
    key = formatKey(key)
    return this.el.hasAttribute(key)
  }

  delete (key) {
    if (this.has(key)) {
      key = formatKey(key)
      this.el.removeAttribute(key)

      return true
    } else {
      return false
    }
  }
}

// try to convert data into JS objects
// just like jQuery: https://github.com/jquery/jquery/blob/master/src/data.js#L24
function coerceDataValue (value) {
  if (value === 'true') {
    return true
  }

  if (value === 'false') {
    return false
  }

  if (value === 'null') {
    return null
  }

  // Only convert to a number if it doesn't change the string
  if (value === +value + '') {
    return +value
  }

  return value
}

function dasherize (value) {
  return value.toString().replace(/([A-Z])/g, (_, char) => `-${char.toLowerCase()}`)
}

function formatKey (key) {
  return `data-${dasherize(key)}`
}
