import Controller from 'core/controller'
import { el } from 'core/dom'
import { map, isArray, debounce, isUndefined, template } from 'lodash'
import { strip, isPresent } from 'core/util/string'
import { fetchJson } from 'core/fetch'

function cleanValue (str) {
  return strip(str + '').toLowerCase()
}

const buildResult = template(`
  <a href="#" data-action="address-book#chooseContact" data-contact-name="<%= name %>" data-contact-email="<%= email %>">
    <% if (isPresent(name)) { %><strong><%= name %></strong> <% } %>
    <%= email %>
  </a>
`, {
  imports: {
    isPresent: isPresent
  }
})

export default class extends Controller {
  static targets = ['menu', 'explainer', 'emailField', 'field', 'dropdown', 'googleNote']

  connect () {
    this.keyUp = debounce(event => this.keyUpDebounced(event), 200)
    this.searches = {}

    if (!this.isConnected) {
      el(this.googleNoteTarget).show()
    }
  }

  get service () {
    return this.data.get('service')
  }

  get isAvailable () {
    return this.data.get('available') === 'true'
  }

  get isConnected () {
    return this.data.get('connected') === 'true'
  }

  get isExpanded () {
    return el(this.dropdownTarget).hasClass('is-open')
  }

  get nameValue () {
    return this.fieldTarget.value + ''
  }

  get shouldPerformSearch () {
    return this.nameValue.length >= 2
  }

  chooseContact (event) {
    event.preventDefault()

    const link = el(event.target).closest('a')
    const name = link.data.get('contactName')
    const email = link.data.get('contactEmail')

    if (!isPresent(email)) {
      return
    }

    el(this.fieldTarget).trigger('eg:picked', {
      name,
      email
    })

    this._updateDropDown('', false)
  }

  keyUpDebounced (event) {
    if (this.shouldPerformSearch) {
      this._performSearch()
    } else {
      this._showContacts([])
    }
  }

  hide (event) {
    el(this.dropdownTarget).removeClass('is-open')
  }

  _performSearch () {
    const name = cleanValue(this.nameValue)
    const existing = this.searches[name]

    if (!this.isConnected) {
      return
    }

    if (isArray(existing)) {
      this._showContacts(existing)
      return
    }

    if (!isUndefined(existing)) {
      return
    }

    this.searches[name] = true

    fetchJson('/contacts', 'get', { q: name, t: (new Date().getTime()) })
      .then(json => {
        if (isArray(json.contacts)) {
          this.searches[name] = json.contacts
          this._showContacts(json.contacts)
        }
      })
  }

  _showContacts (contacts) {
    if (contacts.length === 0) {
      this._updateDropDown('', false)
      return
    }

    const html = map(contacts, contact => buildResult(contact)).join('\n')

    this._updateDropDown(html, true)
  }

  _updateDropDown (contents, isExpanded = true) {
    const dropdown = el(this.dropdownTarget)
    const menu = el(this.menuTarget)

    menu.html(contents)

    if (isExpanded) {
      dropdown.addClass('is-open')
    } else {
      dropdown.removeClass('is-open')
    }
  }
}
