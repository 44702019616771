import 'core/polyfills'
import Scream from 'scream'

// core extensions
import { isMobile } from 'core/responsive'

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

import Rails from 'rails-ujs'
import * as ActiveStorage from 'activestorage'

Rails.start()
ActiveStorage.start()

const stimulusApp = Application.start()
const context = require.context('../controllers', true, /\.js$/)
stimulusApp.load(definitionsFromContext(context))

// trying to detect toolbar visibility on mobile safari
if (isMobile) {
  // using Scream.js to determine mobile viewport changes
  const scream = Scream()

  scream.on('viewchange', e => {
    if (scream.isMinimalView()) {
      document.body.classList.add('wo-toolbar')
    } else {
      document.body.classList.remove('wo-toolbar')
    }
  })
}
