import Controller from 'core/controller'
import { el } from 'core/dom'
import { map, template, each } from 'lodash'
import { strip } from 'core/util/string'
import { isValidEmail, isValidName } from 'core/util/validations'

function cleanEmail (str) {
  return cleanName(str).toLowerCase()
}

function cleanName (str) {
  return strip(str + '').replace(/[^A-Za-z0-9'@\s-_.+]/g, '')
}

const buildRecipient = template(`
  <span class="new-message-person" data-target="new-message.recipient" data-name="<%= name %>" data-email="<%= email %>"><a href="#" data-action="new-message#remove">&times;</a> <%= name %> (<%= email %>)</span>
`)

export default class extends Controller {
  static targets = [
    'another',
    'emailsField',
    'namesField',
    'newEmail',
    'newName',
    'recipient',
    'recipientsContainer',
    'sender'
  ]

  connect () {
    this._saveRecipients()
  }

  add (event) {
    event.preventDefault()

    const wrapper = el(this.anotherTarget)

    if (!wrapper.isVisible) {
      wrapper.show()

      el(this.newNameTarget).focus()
    } else {
      this._addRecipientIfValid(false)
    }
  }

  foundContact (event) {
    if (!event) {
      return
    }

    if (!event.detail) {
      return
    }

    const { name, email } = event.detail

    const nameTarget = el(this.newNameTarget)
    const emailTarget = el(this.newEmailTarget)

    const existingName = cleanName(el(this.newNameTarget).value())

    if (cleanName(name) === '') {
      nameTarget.value(existingName)
    } else {
      nameTarget.value(name)
    }

    emailTarget.value(email)

    this._addRecipientIfValid()
  }

  keyDown (event) {
    // return
    if (event.keyCode === 13) {
      event.preventDefault()

      this._addRecipientIfValid()
    }
  }

  remove (event) {
    event.preventDefault()

    const span = el(event.target).closest('[data-target~="new-message.recipient"]')

    span.fadeAndRemove().then(() => {
      this._saveRecipients()

      if (this.recipientTargets.length === 0) {
        el(this.anotherTarget).show()
        el(this.newNameTarget).focus()
      }
    })
  }

  submit (event) {
    const nameTarget = el(this.newNameTarget)
    const emailTarget = el(this.newEmailTarget)

    const name = cleanName(nameTarget.value())
    const email = cleanEmail(emailTarget.value())

    nameTarget.removeClass('with-error')
    emailTarget.removeClass('with-error')

    // if there is a valid person sitting there, add them
    if (isValidName(name) && isValidEmail(email)) {
      this._addRecipientIfValid(true, false)
    } else if (this.recipientTargets.length === 0) {
      this._addRecipientIfValid(true, false)
    }

    // check to make sure we have at least one recipient
    if (this.recipientTargets.length > 0) {
      return
    }

    event.preventDefault()
  }

  toggleSender (event) {
    event.preventDefault()

    let targetElement

    each(this.senderTargets, target => {
      targetElement = el(target)
      targetElement.toggle()

      if (!targetElement.isVisible) {
        return
      }

      targetElement = targetElement.first('input')

      if (!targetElement) {
        return
      }

      targetElement.select()
    })
  }

  _addRecipientIfValid (andHide = true, andClear = true) {
    const nameTarget = el(this.newNameTarget)
    const emailTarget = el(this.newEmailTarget)

    const name = cleanName(nameTarget.value())
    const email = cleanEmail(emailTarget.value())

    let isValid = true

    nameTarget.removeClass('with-error')
    emailTarget.removeClass('with-error')

    if (!isValidName(name)) {
      nameTarget.addClass('with-error')
      isValid = false
    }

    if (!isValidEmail(email)) {
      emailTarget.addClass('with-error')
      isValid = false
    }

    if (!isValid) {
      return
    }

    const recipient = buildRecipient({
      name,
      email
    })

    el(this.recipientsContainerTarget).append(recipient)

    if (andClear) {
      el(this.newNameTarget).value('')
      el(this.newEmailTarget).value('')
    }

    if (andHide) {
      el(this.anotherTarget).hide()
    } else {
      el(this.newNameTarget).focus()
    }

    this._saveRecipients()
  }

  _saveRecipients () {
    let data
    const names = []
    const emails = []

    map(this.recipientTargets, target => {
      data = el(target).data

      names.push(data.get('name'))
      emails.push(data.get('email'))
    })

    el(this.namesFieldTarget).value(names.join('|'))
    el(this.emailsFieldTarget).value(emails.join('|'))

    if (names.length > 0) {
      el(this.recipientsContainerTarget).show()
    } else {
      el(this.recipientsContainerTarget).hide()
    }
  }
}
