import { Controller } from 'stimulus'
import { el } from 'core/dom'

// small wrapper for stimulus controller to add dom utils
class CoreController extends Controller {
  get el () {
    if (!this._el) {
      this._el = el(this.scope.element)
    }

    return this._el
  }
}

export default CoreController
