import Controller from 'core/controller'
import { el } from 'core/dom'

export default class extends Controller {
  static targets = ['submit']

  onSubmit (event) {
    if (this.hasSubmitTarget) {
      const submit = el(this.submitTarget)
      submit.addClass('disabled')
      submit.value(submit.data.get('disableWith'))
    }
  }
}
