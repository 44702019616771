import ModalController from './modal-controller'
import { el } from 'core/dom'

export default class extends ModalController {
  static targets = ['submitButton']

  _modalDidAppear () {
    el(this.submitButtonTarget).attr('type', 'submit')
  }

  _modalWillDisappear () {
    el(this.submitButtonTarget).attr('type', 'button')
  }
}
