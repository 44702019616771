import Controller from 'core/controller'
import { el, eachEl } from 'core/dom'
import { serializeFormToJson } from 'core/util/form'
import { isPresent } from 'core/util/string'
import { get, isObject } from 'lodash'
import { themeColors } from '../themes/colors'
import { fetchJson } from 'core/fetch'
import { trigger } from 'core/events'
import { localStore } from 'core/storage'

export default class extends Controller {
  static targets = [
    'body',
    'bodyField',
    'destinationField',
    'headline',
    'date',
    'headlineField',
    'layout',
    'names',
    'photo',
    'submitButton',
    'actionButtons',
    'continueButtons'
  ]

  connect () {
    this._loadBrowserAutosave()
    this._validate()
  }

  get bodyColor () {
    return this.data.get('bodyColor')
  }

  get bodyValue () {
    return this.bodyFieldTarget.value
  }

  get headlineColor () {
    return this.data.get('headlineColor')
  }

  get headlineValue () {
    return this.headlineFieldTarget.value
  }

  get isDisabled () {
    return this.submitButtonTarget.disabled === true
  }

  get messageId () {
    return this.data.get('messageId')
  }

  get namesColor () {
    return this.data.get('namesColor')
  }

  get layoutKey () {
    return this.data.get('layout')
  }

  get storageKey () {
    return `electragram:autosave:${this.messageId}`
  }

  get templateKey () {
    return this.data.get('template')
  }

  autoSave (event = null) {
    if (event) {
      event.preventDefault()
    }

    const params = serializeFormToJson(this.element)
    params._autosave = true

    fetchJson(this.el.attr('action'), 'patch', params)
  }

  chooseLayout (event) {
    const radioField = el(event.target)
    const layoutName = radioField.data.get('layout')

    this._setLayout(layoutName)
    this.autoSave()
  }

  chooseTemplate (event) {
    const radioField = el(event.target)
    const prefix = radioField.data.get('prefix')
    const namesColor = radioField.data.get('namesColor')
    const headlineColor = radioField.data.get('headlineColor')
    const bodyColor = radioField.data.get('bodyColor')

    this._setTemplate(prefix, namesColor, headlineColor, bodyColor)
    this.autoSave()
  }

  didChangeField (event) {
    this._validate()
    this._autosaveInBrowser()
  }

  didChangePhoto (event) {
    const autosaveField = document.createElement('input')
    autosaveField.type = 'hidden'
    autosaveField.name = '_autosave'
    autosaveField.value = 'true'

    el(this.destinationFieldTarget).insertAfter(autosaveField)
    this.destinationFieldTarget.value = 'show'
  }

  showColorsModal (event) {
    event.preventDefault()

    trigger('show:change-color')
  }

  showLayoutModal (event) {
    event.preventDefault()

    trigger('show:change-layout')
  }

  showPhotoModal (event) {
    event.preventDefault()

    trigger('show:change-photo')
  }

  submit (event) {
    const hasHeadline = isPresent(this.headlineValue)

    if (!hasHeadline) {
      if (!window.confirm("It looks like you didn't enter a subject for your message. Click OK to continue, or cancel to edit your message.")) {
        event.preventDefault()
        event.stopPropagation()
        return
      }
    }

    this._clearBrowserAutosave()
  }

  _autosaveInBrowser () {
    localStore.setObject(this.storageKey, {
      headline: this.headlineValue,
      body: this.bodyValue
    })
  }

  _clearBrowserAutosave () {
    localStore.del(this.storageKey)
  }

  _disableFormSubmission (shouldDisable) {
    if (shouldDisable) {
      eachEl(this.submitButtonTargets, el => {
        el.addClass('disabled')
        el.element.disabled = true
      })
    } else {
      eachEl(this.submitButtonTargets, el => {
        el.removeClass('disabled')
        el.element.disabled = false
      })
    }
  }

  _loadBrowserAutosave () {
    const local = localStore.getObject(this.storageKey)

    if (!isObject(local)) {
      return
    }

    el(this.headlineFieldTarget).value(local.headline)
    el(this.bodyFieldTarget).value(local.body)
  }

  _setLayout (layout) {
    this.data.set('layout', layout)

    this._updateLayoutClasses()

    const html = document.querySelector('html')

    if (html) {
      html.className = `electra-layout is-msg-${layout}`
    }
  }

  _setTemplate (prefix, namesColor, headlineColor, bodyColor) {
    this.data.set('template', prefix)
    this.data.set('namesColor', namesColor)
    this.data.set('headlineColor', headlineColor)
    this.data.set('bodyColor', bodyColor)

    this._updateLayoutClasses()
  }

  _updateLayoutClasses () {
    if (!this.hasLayoutTarget) {
      return
    }

    this.layoutTarget.className = `composer layout-${this.layoutKey} template-${this.templateKey}`

    const namesColor = get(themeColors, `${this.layoutKey}.names`, this.namesColor)
    const headlineColor = get(themeColors, `${this.layoutKey}.headline`, this.headlineColor)
    const bodyColor = get(themeColors, `${this.layoutKey}.body`, this.bodyColor)

    el(this.namesTarget).attr('style', `color:#${namesColor}`)
    el(this.headlineTarget).attr('style', `color:#${headlineColor}`)
    el(this.bodyTarget).attr('style', `color:#${bodyColor}`)
    el(this.dateTarget).attr('style', `color:#${headlineColor}`)
  }

  _validate () {
    const { bodyValue } = this

    if (isPresent(bodyValue)) {
      this._disableFormSubmission(false)
    } else {
      this._disableFormSubmission(true)
    }
  }
}
