import Controller from 'core/controller'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import { on, off } from 'core/events'

export default class extends Controller {
  connect () {
    this._showEvent = data => this.show(data)
    on(this.eventKey, this._showEvent)
  }

  disconnect () {
    off(this.eventKey, this._showEvent)

    if (this._resetLockEvent) {
      off(`reset:${this.modalKey}`, this._resetLockEvent)
    }
  }

  get modalKey () {
    if (!this._modalKey) {
      this._modalKey = this.el.data.get('controller').replace('-modal', '')
    }

    return this._modalKey
  }

  get eventKey () {
    return `show:${this.modalKey}`
  }

  get modal () {
    if (!this._modal) {
      this._modal = this.element.querySelector('.modal')
    }

    return this._modal
  }

  hide (event) {
    if (event) {
      event.preventDefault()
    }

    this._unlockBody()
    this._modalWillDisappear()
    this.el.removeClass('in')

    setTimeout(() => {
      this.el.removeClass('is-active')
      document.body.classList.remove('with-modal')
      this._modalDidDisappear()
    }, 200)
  }

  show (data) {
    this._modalWillAppear(data)
    this.el.addClass('is-active')

    if (!this._resetLockEvent) {
      this._resetLockEvent = () => this._resetLock()
      on(`reset:${this.modalKey}`, this._resetLockEvent)
    }

    setTimeout(() => {
      this.el.addClass('in')
      document.body.classList.add('with-modal')
      this._lockBody()
      this._modalDidAppear(data)
    }, 50)
  }

  _lockBody () {
    disableBodyScroll(this.modal)
    this.element.scrollTop = 0
    this.element.focus()
  }

  _resetLock () {
    clearAllBodyScrollLocks()
    disableBodyScroll(this.model)
  }

  _unlockBody () {
    clearAllBodyScrollLocks()
  }

  _modalDidAppear (data) {
    // available for subclass
  }

  _modalDidDisappear () {
    // available for subclass
  }

  _modalWillAppear (data) {
    // available for subclass
  }

  _modalWillDisappear () {
    // available for subclass
  }
}
