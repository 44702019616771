import { assign, each, isObject, isArray, isFunction, isNull } from 'lodash'

export const rSpaces = /%20/g

function buildParam (key, value, append) {
  if (isArray(value)) {
    each(value, (subValue) => {
      buildParam(`${key}[]`, subValue, append)
    })
  } else if (isObject(value)) {
    each(value, (subValue, subKey) => {
      buildParam(`${key}[${subKey}]`, subValue, append)
    })
  } else {
    append(key, value)
  }
}

export function toParam (object) {
  const result = []

  const append = function append (key, value) {
    if (isFunction(value)) {
      value = value()
    }

    if (isNull(value)) {
      value = ''
    }

    result.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
  }

  let value

  each(Object.keys(object), key => {
    value = object[key]

    buildParam(key, value, append)
  })

  return result.join('&').replace(rSpaces, '+')
}

// returns the rails authenticity verification param
export function csrfParams (existingParams = {}) {
  const param = document.querySelector('meta[name=csrf-param]').content
  const token = document.querySelector('meta[name=csrf-token]').content

  return assign({}, existingParams, {
    [param]: token
  })
}
